import React, { useState } from "react";
import tw, { styled, css } from "twin.macro";
import { Link as Glink } from "gatsby";
import { HiOutlinePlus, HiOutlineMinus } from "react-icons/hi";
import AnimateHeight from "react-animate-height";

const Item = styled.li(({ expanded }) => [
  tw`leading-none! text-xl text-[#040609]`,
  css`
    ul {
      ${tw`before:(content[''] bg-primary w-px h-full absolute left-0 top-[-1px])`}

      ul {
        ${tw`before:hidden`}
      }
    }
  `,
]);
const LabelWrapper = tw.div`flex flex-row items-center w-full`;
const Index = tw.div`font-mazuisReg text-primary text-base`;
const Top = tw.div`flex flex-row justify-start items-center relative `;
const Link = styled(Glink)(({ isChild }) => [
  tw`font-gothic hover:text-primary flex py-4 tracking-wider uppercase duration-500 ease-in-out`,
  isChild && tw`font-mont py-2 text-base capitalize`,
]);
const Expand = styled.button(({ expanded }) => [
  tw`hover:text-primary text-base duration-500 ease-in-out`,
  expanded && tw`text-primary `,
]);
const Children = tw.ul`relative ml-[2.25rem] `;
const LinkWrapper = styled.div(({ isChild, expanded }) => [
  tw`relative flex flex-row items-center  w-full gap-3 after:(content[''] bg-primary h-px w-full absolute bottom-0 left-0 duration-500 ease-in-out)`,
  isChild && tw`after:hidden`,
  expanded && tw`after:w-0`,
]);

const NumberedNavItem = ({
  listFunc,
  label,
  path,
  children,
  parentId,
  index,
  isChild,
  ...rest
}) => {
  const [expanded, setExpanded] = useState(false);
  const toggleExpanded = () => setExpanded(!expanded);
  const isChildren = children && children.length > 0;
  if (index.length === 1) index = `0${index}`;

  return (
    <Item {...rest} expanded={expanded}>
      <Top>
        <LabelWrapper>
          <div tw="flex relative w-10">
            {!isChild && <Index>{index}</Index>}
          </div>
          <LinkWrapper expanded={expanded} isChild={isChild}>
            <Link isChild={isChild} to={path}>
              {label}
            </Link>
            {isChildren && (
              <Expand onClick={toggleExpanded} expanded={expanded}>
                {expanded ? <HiOutlineMinus /> : <HiOutlinePlus />}
              </Expand>
            )}
          </LinkWrapper>
        </LabelWrapper>
      </Top>
      {isChildren && (
        <AnimateHeight
          height={expanded ? "auto" : 0}
          duration={500}
          easing="ease-in-out"
        >
          <Children>{listFunc(children)}</Children>
        </AnimateHeight>
      )}
    </Item>
  );
};

export default NumberedNavItem;
