import React from "react";
import { useInView } from "react-intersection-observer";
import tw, { styled } from "twin.macro";

import Item from "./NumberedNavItem";

const Nav = styled.nav(({ inview, side }) => [
  tw`duration-1000 ease-in-out translate-x-[150px] opacity-0`,
  !side && tw`pr-32`,
  inview && tw`translate-x-0 opacity-100`,
]);
const Title = tw.h3`font-mazuis italic text-primary text-4xl mb-4 pl-8`;
const Menu = tw.ul`flex flex-col`;

const NumberedNav = ({ menu, title, categories, ...rest }) => {
  const [ref, inView] = useInView({
    threshold: 0,
    triggerOnce: true,
  });

  const listItems = (items, position = "child") =>
    items.map((item, i) => {
      item = !!categories ? item.node : item;
      return (
        <Item
          {...item}
          isChild={position === "child"}
          listFunc={listItems}
          key={i}
          index={String(i + 1)}
        />
      );
    });

  return (
    <Nav {...rest} ref={ref} inview={inView}>
      {title && <Title>{title}</Title>}
      <Menu>{listItems(menu, "top")}</Menu>
    </Nav>
  );
};

export default NumberedNav;
