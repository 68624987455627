import React from "react";
import { Link } from "gatsby";
import tw, { styled } from "twin.macro";

const Container = styled.div(({ sidebar }) => [
  sidebar === true && tw`px-12 py-24 mt-56 ml-10 shadow-2xl`,
]);

const Title = styled.h2(({ sidebar }) => [tw`text-font text-3xl font-bold`]);
const List = styled.ul(({ sidebar }) => [
  tw`list-disc ml-12 mt-4 text-lg [a]:(text-primary underline hover:text-gold)`,
]);

const RelatedPosts = ({ posts, sidebar, ...rest }) => {
  return (
    <Container sidebar={sidebar} {...rest}>
      <Title sidebar={sidebar}>Related Posts</Title>

      <List sidebar={sidebar}>
        {posts.map(({ uri, title }, i) => (
          <li key={i} tw="my-4">
            <Link to={uri}>{title}</Link>
          </li>
        ))}
      </List>
    </Container>
  );
};

export default RelatedPosts;
