import React from "react";
import { graphql } from "gatsby";
import tw from "twin.macro";
import { getBreadcrumbs } from "../../lib/functions";

import Layout from "../components/Layout";
import PageWrapper from "../components/PageWrapper";
import ContentWrapper from "../components/ContentWrapper";
import Content from "../components/PageContent";
import SideWrapper from "../components/SideWrapper";
import Sidenavs from "../components/Sidenavs";
import SideForm from "../components/SideForm";
import RelatedPosts from "../components/RelatedPosts";
import InnerTitleBlock from "../components/InnerTitleBlock";
import YourTeam from "../components/YourTeam";
import CaseResults from "../components/CaseResults";
import Awards from "../components/Awards";
import FrontReviews from "../components/FrontReviews";

const Post = ({ data }) => {
  const { seo, title, date, content } = data.wpPost;
  const relatedPosts = data.relatedPosts.nodes;

  return (
    <Layout inner seo={seo}>
      <InnerTitleBlock header={title} breadcrumbs={getBreadcrumbs} />
      <PageWrapper inner>
        <ContentWrapper>
          <div tw="font-bold mb-6 text-primary">{date}</div>
          <Content article content={content} />
          {relatedPosts && <RelatedPosts tw="mt-8" posts={relatedPosts} />}
        </ContentWrapper>
        <SideWrapper>
          <SideForm />
          <Sidenavs showCategories />
        </SideWrapper>
      </PageWrapper>
      <FrontReviews />
      <YourTeam />
      <CaseResults />
      <Awards inner />
    </Layout>
  );
};

export const query = graphql`
  query ($id: String!) {
    wpPost(id: { eq: $id }) {
      title
      date(formatString: "MMMM DD, YYYY")
      content
      featuredImage {
        ...FeaturedImage
      }
      ...PostSEO
    }
    relatedPosts: allWpPost(
      filter: { id: { ne: $id } }
      sort: { fields: date }
      limit: 5
    ) {
      nodes {
        uri
        title
      }
    }
  }
  fragment FeaturedImage on WpNodeWithFeaturedImageToMediaItemConnectionEdge {
    node {
      altText
      localFile {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, quality: 90)
        }
      }
    }
  }
`;

export default Post;
